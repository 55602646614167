import * as React from 'react'
import styles from './Table.module.scss'
import { ArrowUp } from '@phosphor-icons/react'
import cn from 'classnames'
import { motion, Variants } from 'framer-motion'

export type HeaderProps = Omit<
  React.ComponentPropsWithoutRef<'th'>,
  'onClick'
> & {
  /**
   * Sort direction for the column, this will show a relevant arrow
   */
  sort?: 'asc' | 'desc'
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  /**
   * Whether or not to show text in the header for everyone or just screen readers
   */
  srOnly?: boolean
  /**
   * Width of the column
   */
  width?: string
}

const variants: Variants = {
  asc: { rotate: 0, opacity: 100 },
  desc: { rotate: 180, opacity: 100 },
}

export const Header = (props: HeaderProps) => {
  const {
    children,
    className,
    srOnly,
    sort,
    onClick,
    width = 'auto',
    ...restProps
  } = props

  return (
    <th
      className={cn(styles.header, className)}
      style={{ width }}
      {...restProps}
    >
      <Wrapper onClick={onClick}>
        <span className={cn(srOnly && 'sr-only')}>{children}</span>
        {sort && (
          <motion.div
            initial={false}
            className={styles.arrow}
            animate={sort}
            exit={{ opacity: 0 }}
            variants={variants}
            transition={{ duration: 0.2 }}
          >
            <ArrowUp weight="bold" />
          </motion.div>
        )}
      </Wrapper>
    </th>
  )
}

const Wrapper = ({
  children,
  onClick,
  srOnly,
}: {
  children: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  srOnly?: boolean
}) =>
  onClick ? (
    <button
      type="button"
      onClick={onClick}
      className={cn(styles.headerContent, srOnly && 'sr-only')}
    >
      {children}
    </button>
  ) : (
    <div className={cn(styles.headerContent, srOnly && 'sr-only')}>
      {children}
    </div>
  )
